<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="日志时间段">
            <el-date-picker
              @change="dataChange($event, ['starttime', 'endtime'])"
              v-model="dataval"
              type="datetimerange"
              range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @focus="selectData"
              :default-time="['00:00:00', '00:00:00']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="操作人">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.username"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="参数">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.params"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="操作">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.operation"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="selectlist">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table" style="border: none">
        <el-table :data="arrlist.list" @sort-change="sortchange" border>
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            sortable="custom"
            label="创建时间"
          >
            <template v-slot="res">
              {{ mydateFormat(res.row.createDate, "时分秒") }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="ip" label="ip地址">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="username" label="操作人">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="params" label="参数">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="operation" label="操作">
          </el-table-column>
        </el-table>
        <div class="paginations" v-show="arrlist.list && arrlist.list.length">
          <el-pagination
            :page-size="conditions.limit"
            layout="total,prev, pager,next, jumper"
            :total="arrlist.totalCount"
            @current-change="getlist"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import { antiShake } from "@/plugins/other.js";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit:15,
        username: "", //操作人
        starttime: "", //开始时间
        endtime: "", //结束时间
        params: "",
        operation: "",
      },
      dataval: [],
      defaultTime: ["00:00:00", "00:00:00"],
      arrlist: [],
    };
  },
  components: {
    mTitle,
  },
  created() {
    this.getlist();
  },
  methods: {
    async getlist(num) {
      typeof num === "number" && (this.conditions.page = num);
      const { data } = await this.$http(
        "api1",
        "/sys/log/logList",
        "get",
        this.conditions
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.arrlist = data.page;
    },
    selectData() {
      let time = new Date(),
        HH = (time.getHours() - 1 + "").padStart(2, 0),
        HH2 = (time.getHours() + "").padStart(2, 0),
        mm = (time.getMinutes() + "").padStart(2, 0),
        ss = (time.getSeconds() + "").padStart(2, 0);
      this.defaultTime = [`${HH}:${mm}:${ss}`, `${HH2}:${mm}:${ss}`];
    },
    dataChange(event, prop) {
      this.conditions[prop[0]] = (event && new Date(event[0])) || null;
      this.conditions[prop[1]] = (event && new Date(event[1])) || null;
    },
    sortchange(event) {
      if (event.order == "ascending") {
        this.conditions.sidx = event.prop;
        this.conditions.order = "asc";
      } else if (event.order == "descending") {
        this.conditions.sidx = event.prop;
        this.conditions.order = "desc";
      } else {
        this.conditions.sidx = "";
        this.conditions.order = "";
      }
      this.getlist();
    },
    selectlist() {
      antiShake((_) => {
        this.conditions.page = 1;
        this.getlist();
      });
    },
  },
};
</script>
<style lang="scss" src="@/assets/components.scss" scoped></style>
